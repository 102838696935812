module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: "OHMYCODE - A blog by Sean Ye", // Navigation and Site Title
  siteTitleAlt: "OHMYCODE - A blog by Sean Ye", // Alternative Site title for SEO
  siteTitleShort: "OhMyCode", // short_name for manifest
  siteHeadline: "Rails, React, and More.", // Headline for schema.org JSONLD
  siteUrl: "https://ohmycode.blog", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteLogo: "/logos/logo.png", // Used for SEO and manifest
  siteDescription:
    "Personal blog by Sean Ye. I talk about Ruby on Rails, ReactJS, and many other topics.",
  author: "Sean Ye", // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: "@SeanYeOMC", // Twitter Username
  ogLanguage: "en_CA", // og:language
  googleAnalyticsID: "UA-146432432-1",

  // Manifest and Progress color
  themeColor: "#3498DB",
  backgroundColor: "#2b2e3c",

  // Your information
  name: "Sean Ye",
  location: "Canada",
  socialMedia: [
    {
      url: "https://twitter.com/SeanYeOMC",
      name: "Twitter"
    },
    {
      url: "https://www.ohmycode.blog",
      name: "Homepage"
    }
  ]
};
