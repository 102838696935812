/* eslint no-unused-expressions: off */
import React from "react";
import PropTypes from "prop-types";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { MDXProvider } from "@mdx-js/react";
import { SEO, UnorderedList, OrderedList, ListItem, Pre } from "./index";
import theme from "../../config/theme";
import reset from "../styles/reset";

const mdxComponents = {
  ul: props => <UnorderedList {...props} />,
  ol: props => <OrderedList {...props} />,
  li: props => <ListItem {...props} />,
  pre: props => <Pre {...props} />
};

const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
  }

  body {
    background: url("${props => props.theme.bgPattern}") #000;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
  }

  h2 {
    margin-top: 8rem;
  }

  h5 {
    margin-top: 3rem;
  }

  code {
    color: #5291f8;
    background: #282828;
  }

  .gatsby-resp-image-wrapper {
    margin: 2.75rem 0;
  }
`;

const AbsoluteWrapper = styled.main`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const Layout = ({ children, customSEO }) => (
  <ThemeProvider theme={theme}>
    <>
      {!customSEO && <SEO />}
      <GlobalStyle />
      <noscript>To browse this site, please enable JavaScript.</noscript>
      <MDXProvider components={mdxComponents}>
        <AbsoluteWrapper>{children}</AbsoluteWrapper>
      </MDXProvider>
    </>
  </ThemeProvider>
);

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  customSEO: PropTypes.bool
};

Layout.defaultProps = {
  customSEO: false
};
